<template>
  <c-alert-dialog :is-open="isOpen" :least-destructive-ref="$refs.cancelRef">
    <c-alert-dialog-overlay z-index="1" />
    <c-alert-dialog-content
      text-align="center"
      z-index="2"
      w="488px"
      top="40px"
      py="40px"
      px="40px"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.1)"
      border-radius="12px"
    >
      <c-image
        w="176px"
        mx="auto"
        mb="49px"
        :src="require('@/assets/icon-confirm-delete.svg')"
      />
      <c-alert-dialog-body
        text-align="center"
        mb="35px"
        font-weight="500"
        font-size="18px"
        line-height="27px"
        v-html="content"
      />
      <c-alert-dialog-footer p="0">
        <c-button
          w="50%"
          h="auto"
          py="17px"
          border-radius="60px"
          ref="cancelRef"
          @click="onSubmit(false)"
        >
          Batal
        </c-button>
        <c-button
          w="50%"
          h="auto"
          py="17px"
          border-radius="60px"
          variantColor="red"
          @click="onSubmit(true)"
          ml="3"
          min-w="80px"
        >
          Hapus
        </c-button>
      </c-alert-dialog-footer>
    </c-alert-dialog-content>
  </c-alert-dialog>
</template>

<script>
export default {
  name: "SuperadminDeleteConfirm",
  props: ["isOpen", "content"],
  methods: {
    onSubmit(state) {
      this.$emit("on-delete", state);
    },
  },
};
</script>